<template>
  <v-container id="system-configuration" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <div class="text-center" v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">System Configuration</div>
          </template>
          <v-form v-if="!loading">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="customer_list"
                    :label="`列表任務？`"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="列表任務數量"
                    v-model="customer_list_count"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    label="列表預設系統"
                    class="purple-input"
                    v-model="default_list"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-switch
                    v-model="customer_tp"
                    :label="`謄本任務？`"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="謄本任務數量"
                    v-model="customer_tp_max"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    label="謄本登序數量"
                    class="purple-input"
                    v-model="max_regno_count"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="customer_normal"
                    :label="`混合任務？`"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="6">
                  <v-switch
                    v-model="customer_full_tp"
                    :label="`造冊`"
                  ></v-switch>
                </v-col>
                <!--  owner diff-->
                <!-- <v-col cols="12" md="4">
                  <v-switch
                    v-model="owner_diff_enable"
                    :label="`Owner Diff`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Owner diff數量"
                    v-model="owner_diff"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Owner 最大"
                    v-model="owner_diff_threads"
                  />
                </v-col> -->
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="cop_owner_diff_enable"
                    :label="`COP Owner Diff Enable`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    label="COP Owner diff數量"
                    v-model="cop_owner_count"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="cop_creditor_diff_enable"
                    :label="`COP Creditor Diff Enable`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="2">
                  <v-switch
                    v-model="auto_dispatch_epland"
                    :label="`Auto  epland`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    class="purple-input"
                    label="帳號"
                    v-model="auto_dispatch_account"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    class="purple-input"
                    label="數量"
                    v-model="auto_dispatch_count"
                  />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="updateConfiguration"
                  >
                    Update Configuration
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    loading: false,
    customer_full_tp: false,
    customer_list: false,
    customer_list_count: 4,
    customer_normal: false,
    customer_tp: false,
    customer_tp_max: 10,
    default_list: 2,
    default_tp: 0,
    max_regno_count: 5,
    owner_diff_enable: false,
    owner_diff: 4,
    owner_diff_threads: 1,
    cop_owner_diff_enable: false,
    cop_creditor_diff_enable: false,
    cop_owner_count: 5,
    auto_dispatch_epland: false,
    auto_dispatch_account: 8,
    auto_dispatch_count: 40,
    id: null,
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    getConfiguration() {
      const currentObj = this;
      currentObj.loading = true;

      const url = process.env.VUE_APP_SERVER_URL + "/backend/configuration/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.setConfigure(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    setConfigure(data) {
      if (data.count > 0) {
        console.log("get result");
        let result = data.results[0];
        console.log(result);
        this.customer_full_tp = result.customer_full_tp;
        this.customer_list = result.customer_list;
        this.customer_list_count = result.customer_list_count;
        this.customer_normal = result.customer_normal;
        this.customer_tp = result.customer_tp;
        this.customer_tp_max = result.customer_tp_max;
        this.default_list = result.default_list;
        this.default_tp = result.default_tp;
        this.max_regno_count = result.max_regno_count;
        this.id = result.id;
        this.owner_diff_enable = result.owner_diff_enable;
        this.owner_diff = result.owner_diff;
        this.owner_diff_threads = result.owner_diff_threads;
        this.cop_owner_diff_enable = result.cop_owner_diff_enable;
        this.cop_owner_count = result.cop_owner_count;
        this.cop_creditor_diff_enable = result.cop_creditor_diff_enable;
        this.auto_dispatch_epland = result.auto_dispatch_epland;
        this.auto_dispatch_account = result.auto_dispatch_account;
        this.auto_dispatch_count = result.auto_dispatch_count;
      }
    },
    updateConfiguration() {
      if (this.id == null) {
        return;
      }
      const currentObj = this;
      currentObj.loading = true;
      const url = `${process.env.VUE_APP_SERVER_URL}/backend/configuration/${this.id}/`;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      let jsonData = {
        customer_full_tp: this.customer_full_tp,
        customer_list: this.customer_list,
        customer_list_count: this.customer_list_count,
        customer_normal: this.customer_normal,
        customer_tp: this.customer_tp,
        customer_tp_max: this.customer_tp_max,
        default_list: this.default_list,
        default_tp: this.default_tp,
        max_regno_count: this.max_regno_count,
        owner_diff_enable: this.owner_diff_enable,
        owner_diff: this.owner_diff,
        owner_diff_threads: this.owner_diff_threads,
        cop_owner_diff_enable: this.cop_owner_diff_enable,
        cop_owner_count: this.cop_owner_count,
        cop_creditor_diff_enable: this.cop_creditor_diff_enable,
        auto_dispatch_epland: this.auto_dispatch_epland,
        auto_dispatch_account: this.auto_dispatch_account,
        auto_dispatch_count: this.auto_dispatch_count,
      };
      console.log("this auto dispatch epland " + this.auto_dispatch_epland);

      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          // console.log(response.data);
          console.log("patch done");
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
  },
  mounted() {
    this.getConfiguration();
  },
};
</script>
